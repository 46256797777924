import * as React from 'react'

const NotFoundPage = () => {
	return (
		<main>
			<h2>404</h2>
		</main>
	)
}

export default NotFoundPage
